@import "../../variables";

#admin-page-manage-albums {
  #page-title {
    #left-part {
      align-items: flex-end;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      #left-part {
        width: 100%;
        flex-wrap: wrap;

        button {
          width: 50px;
        }

        #edit-title {
          flex: 1;
        }

        .photos-count {
          flex: 1 1 100%;
          margin-left: 70px;
        }
      }
    }
  }

  #album-description {
    .MuiFormControl-root {
      background: white;
      border-radius: $borderRadius;
    }
  }

  .save-button {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
  }

  #photo-list {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;

    .photo {
      padding: 15px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      border: 2px solid $borderColor;
      border-radius: $borderRadius;
      position: relative;

      &.photo--new {
        border-style: dashed;
        background: #fff;

        //.cover-photo {
        //  height: 150px;
        //  flex: 1;
        //
        //  .draggable-content {
        //    height: 150px;
        //    padding-left: calc(50% - 37.5px);
        //    display: flex;
        //    flex-direction: column;
        //    justify-content: center;
        //    cursor: pointer;
        //
        //    &:hover {
        //      transition: 0.3s;
        //      scale: 1.2
        //    }
        //
        //    #page-loader {
        //      position: absolute;
        //      padding-left: 0;
        //      padding-top: unset;
        //    }
        //
        //    svg {
        //      width: 75px;
        //      height: 75px;
        //    }
        //  }
        //}


      }

      .title {

        .MuiInput-input {
          font-size: 1.4rem;
        }
      }

      @media(max-width: 968px) {
        flex-direction: column;
        align-items: center;

        .photo-details {
          margin-left: 0;
          width: 100%;
          max-width: 450px;
        }
      }
    }
  }



}