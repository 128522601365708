$headerSize: 60px;
$pageMargin: 15px;
$headerOffset: 25px;
$borderColor: #999;
$borderRadius: 30px;
$backgroundColor: #fff;
$accentColor: #de5200;

$sidebar_mobile_breakpoint: 768px;

$sidebar_mobile_height: 30vh;

$sidebar_width_1920: 15vw;
$sidebar_width_1024_1920: 25vw;
$sidebar_width_768_1024: 35vw;
$sidebar_width_480_768: 50vw;

body {
  --color-accent: #de5200;
}