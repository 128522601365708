@import "variables";

//margin: 0 auto;
//width: 100%;
//max-width: 1280px;
//flex: 1;
//display: flex;
//flex-direction: column;
//padding: 0 $pageMargin $pageMargin;

//#page {
//  position: relative;
//  background: #f6f6f6;
//  flex: 1;
//  border: 2px solid $accentColor;
//  border-radius: $borderRadius;
//  padding: $borderRadius;
//}

#page-title {
  font-size: 1.8rem;
  line-height: 2em;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  #left-part {
    display: flex;
    justify-content: flex-start;

    button {
      padding: 0;
      font-size: 2rem;
      height: 1.8em;

      svg#navigate-back {
        width: 1.8em;
        height: 1.8em;
      }
    }

    .input-element {
      margin-bottom: 0;
      margin-left: 15px;
    }

    .photos-count {
      font-size: 0.6em;
      line-height: 2em;
      color: #333;
      font-style: italic;
      margin-left: 15px;
    }
  }

  //.MuiInput-input {
  //  font-size: 2rem;
  //}
}

#page-loader {
  position: absolute;
  padding-left: 50%;
  padding-top: 120px;
  z-index: 99999;

  > span > span {
    background-color: $accentColor !important;
  }
}

.card-layout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  .card-details {
    flex: 1 1 auto;
    height: 100%;
    padding-top: 25px;

    @media(max-width: 968px) {
      margin-left: 0;
      width: 100%;
      max-width: 450px;
    }

    .input-element {
      margin-bottom: 25px;

      .input-error {
        visibility: hidden;
        position: absolute;
        bottom: -1.4em;
        left: 25px;
        font-size: 11pt;
        color: red;
        font-style: italic;
      }
    }
  }

  .input-buttons {
    display: flex;
    justify-content: space-between;

    @media(max-width: 968px) {
      flex-direction: column;
    }

    .left-buttons {
      display: flex;

      button {
        margin-right: 15px;
      }

      @media(max-width: 968px) {
        justify-content: space-between;
        margin-bottom: 15px;
        button {
          margin-right: 0;
        }
      }
    }

    .right-buttons {
      display: flex;

      button {
        margin-left: 15px;
      }

      @media(max-width: 968px) {
        justify-content: space-between;
        margin-bottom: 15px;

        button {
          margin-left: 0;
        }
      }
    }
  }
}

.cover-photo {
  //position: relative;
  //height: 350px;
  //max-width: 100%;
  ////margin-bottom: 3px;
  ////margin-right: 25px;
  //cursor: pointer;
  //
  //> img {
  //  transform: scale(1);
  //}
  //
  //svg {
  //  color: $accentColor;
  //  position: absolute;
  //  left: calc(50% - 32px);
  //  top: calc(50% - 32px);
  //  width: 64px;
  //  height: 64px;
  //  opacity: 0.8;
  //  z-index: 888;
  //
  //  &.add {
  //    visibility: visible;
  //  }
  //
  //  &.edit {
  //    visibility: hidden;
  //  }
  //}
  //
  //&.cover-photo--bordered {
  //  border-radius: $borderRadius;
  //  box-shadow: #333 5px 5px 5px;
  //  overflow: hidden;
  //}

  .file-name {
    position: absolute;
    bottom: 0;
    background: white;
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    font-style: italic;
    line-height: 1.2em;
    padding: 0 20px;
  }

  //&.cover-photo--no-img {
  //  //padding-left: calc(350px / 2 - 37.5px);
  //  display: flex;
  //  align-items: center;
  //  flex-direction: column;
  //  justify-content: center;
  //
  //  #page-loader {
  //    padding: 9px;
  //  }
  //
  //  svg.no-photos {
  //    width: 75px;
  //    height: 75px;
  //    color: $accentColor;
  //  }
  //}
}

.upload-error {
  width: 100%;
  padding: 25px;
  bottom: 0;
  text-align: center;
  position: absolute;
  font-size: 11pt;
  color: red;
  font-style: italic;
}

.upload-success {
  width: 100%;
  padding: 25px;
  bottom: 0;
  text-align: center;
  position: absolute;
  font-size: 11pt;
  color: green;
  font-style: italic;
}

#photosUpload {
  display: none;
}

.error-box {
  padding: 15px;
  background: lighten(red, 46);
  border: 2px solid crimson;
  border-radius: $borderRadius;
  color: crimson;
  font-size: 1rem;
  margin-bottom: 15px;
}
