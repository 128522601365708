@import "../variables";

header#admin-header {
  margin: 0;
  width: 100%;
  max-width: 1280px;
  flex: 0 0 $headerSize;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  #topNavigation {
    display: flex;
    flex: 1 1 50vw;
    margin: 10px calc($pageMargin + $headerOffset);

    .instance-select {
      width: 200px;
      max-width: 200px;
    }

    #logo {
      flex: 0 0 calc($headerSize - 15px);
      height: calc($headerSize - 20px);
      margin: 10px calc($pageMargin + $headerOffset);
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
        color: $accentColor;
      }
    }
  }

  #user-icon {
    flex: 0 0 calc($headerSize - 20px);
    height: calc($headerSize - 20px);
    margin: 10px calc($pageMargin + $headerOffset);
    position: relative;

    > button {
      width: 100%;
      height: 100%;
      border: 3px solid $accentColor;
      border-radius: 50%;
      padding: 5px;

      svg {
        width: 100%;
        height: 100%;
        color: $accentColor;
      }
    }
  }

  #user-icon-dropout {
    position: absolute;
    background: white;
    bottom: -130px;
    right: -$headerOffset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
    border: 2px solid $borderColor;
    border-radius: $borderRadius;
    padding: 15px 50px 30px;
    line-height: 2.2em;
    z-index: 999;
    color: #000;

    button {
      bottom: 0;
      width: 100%;
    }
  }
}

