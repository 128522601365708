@import "../../variables";

#instance-selector-page {
  #instance-list {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;

    > .instance {

      .title {
        display: flex;

        .MuiFormControl-root {
          margin-right: 50px;
        }

        .MuiInput-input {
          font-size: 1.4rem;
        }

        .MuiButtonBase-root {
          flex: 0 0 100px;
        }
      }

      .instance-links {
        margin-top: 10px;
      }
      div.instance-link {
        padding: 2px 0;
        a {
          color: $accentColor;
          text-decoration: none;
        }
      }

      @media(max-width: 968px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

}