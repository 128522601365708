@import "../../variables";

#portfolio-manager {

  #page-title {
    #left-part {
      align-items: first baseline;
    }
  }

  #album-list {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;

    > .album-list-item {

      .title {
        display: flex;

        .MuiFormControl-root {
          margin-right: 50px;
        }

        .MuiInput-input {
          font-size: 1.4rem;
        }

        .MuiButtonBase-root {
          flex: 0 0 100px;
        }
      }

      @media(max-width: 968px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
